// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyCdJFhoRB3wHsP2Yx_p4JgtDwoKNuQLt5E",
  authDomain: "yvomdnew1.firebaseapp.com",
  databaseURL: "https://yvomdnew1-default-rtdb.firebaseio.com",
  projectId: "yvomdnew1",
  storageBucket: "yvomdnew1.appspot.com",
  messagingSenderId: "377163070861",
  appId: "1:377163070861:web:f09dc118d5c083059351c4",
  measurementId: "G-JLKWD6D964"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };